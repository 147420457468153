import { type PropsWithChildren } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import type { ListRealEstate } from 'src/types/real-estate-list';
import type { Language } from 'src/types/translations';

import { getRealEstateUrl } from 'src/utils/locations';

import { Actions } from './Actions';
import { HideButton } from './Actions/HideButton';
import { NoteButton } from './Actions/NoteButton';
import { SaveButton } from './Actions/SaveButton';
import { AgencyLogo } from './AgencyLogo';
import { ListingCardContext } from './context';
import { Description } from './Description';
import { FeatureList } from './FeatureList';
import { Price } from './Price';
import { Property } from './Property';
import { PropertyContent } from './PropertyContent';
import { PropertyMedia } from './PropertyMedia';
import { SavedNote } from './SavedNote';
import { Title } from './Title';
import { TransactionDate } from './TransactionDate';
import { Units } from './Units';

import css from './styles.module.scss';

interface RootProps extends PropsWithChildren {
  realEstate: ListRealEstate;
  idGeoHash: string;
  hasContactedMessage?: boolean;
  isCompact?: boolean;
}

export function ListingCardRoot({
  realEstate,
  idGeoHash,
  hasContactedMessage = false,
  isCompact = false,
  children,
}: RootProps) {
  const { locale: currentLanguage } = useRouter();

  const { id, visibility, isMosaic } = realEstate;

  const realEstateUrl = getRealEstateUrl({
    id,
    lang: currentLanguage as Language,
  });

  // cards with low visibility have photos with a ratio of 3/2 instead of 4/3,
  // so card's content has less space available
  const hasLowVisibility =
    !isMosaic &&
    (!visibility || visibility === 'premium' || visibility === 'base');

  return (
    <ListingCardContext.Provider
      value={{
        realEstate,
        realEstateUrl,
        isCompact,
        isMosaic,
        hasLowVisibility,
        idGeoHash,
      }}
    >
      <div
        className={clsx(
          css['in-listingCard'],
          hasContactedMessage && 'is-contacted'
        )}
        id={id.toString()}
      >
        {children}
      </div>
    </ListingCardContext.Provider>
  );
}

export const ListingCard = Object.assign(ListingCardRoot, {
  Property,
  PropertyMedia,
  PropertyContent,
  AgencyLogo,
  TransactionDate,
  Price,
  Title,
  Units,
  Description,
  SavedNote,
  FeatureList,
  Actions,
  HideButton,
  SaveButton,
  NoteButton,
});
