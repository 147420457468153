import { createElement } from 'react';
import { clsx } from 'clsx';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import { Photos } from './';
import type { PhotoMosaicProps } from './PhotoMosaic';

import css from './mosaic.module.scss';

interface MosaicVerticalProps extends PhotoMosaicProps {
  isCompact?: boolean;
}

export function MosaicVertical({
  photoData,
  secondaryImagesCustomClass,
  secondaryRatio,
  lazyLoad,
  isCompact = false,
}: MosaicVerticalProps) {
  const photos = photoData.multimedia.photos;

  const visiblePhotos = isCompact ? 3 : 4;

  const photoCustomData = {
    ...photoData,
    customClass: clsx(css['in-mosaic__item'], photoData.customClass),
  };

  if (!photos) return;

  const placeholderOffset = photos.length || 1;

  return (
    <div
      className={clsx(
        css['in-mosaic__container--vertical'],
        isCompact && 'is-compact'
      )}
    >
      {createElement(Photos, photoCustomData)}
      {photos.slice(1, visiblePhotos).map((photo, i) => {
        return (
          <PepitaFigure
            key={i}
            as="figure"
            ratio={secondaryRatio}
            customClass={clsx(
              css['in-mosaic__item'],
              secondaryImagesCustomClass
            )}
            figureContent={photo.urls.small}
            alt={photo.caption}
            lazyLoad={lazyLoad}
            fadeIn
          />
        );
      })}
      {photos.length < visiblePhotos &&
        Array.from(Array(visiblePhotos - placeholderOffset), (_, i) => (
          <PepitaFigure
            key={i}
            as="figure"
            ratio={secondaryRatio}
            customClass={clsx(
              css['in-mosaic__item'],
              secondaryImagesCustomClass
            )}
            figureContent={photoData.emptyState}
            fadeIn
          />
        ))}
    </div>
  );
}
