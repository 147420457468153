import { atom } from 'jotai';

export type HighlightedRealEstate = {
  idGeoHash?: string;
  location?: {
    lat: number;
    lng: number;
  };
};

export const highlightedRealEstateAtom = atom<
  HighlightedRealEstate | undefined
>(undefined as unknown as HighlightedRealEstate);
