import type { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { ContactFormDialog } from 'src/components/ContactForm';

import type { ContactSource } from 'src/libs/contact';
import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { AgencyLite, PhoneTypes } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';

import {
  REAL_ESTATE_TRACKING_SECTIONS,
  useRealEstateTracking,
} from 'src/views/RealEstate/hooks/useRealEstateTracking';

interface ContactFormButtonProps {
  realEstate: ListRealEstate;
  source: ContactSource;
  agency?: AgencyLite;
  isCompact?: boolean;
}

export function ContactFormButton({
  realEstate,
  source,
  agency,
  isCompact = false,
}: ContactFormButtonProps) {
  const { trans } = useTranslations();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {
    trackMessagingModalSubmit,
    trackRealEstatePhoneContact,
    trackSubmitContact,
  } = useRealEstateTracking({
    section: REAL_ESTATE_TRACKING_SECTIONS.LIST,
    realEstateId: realEstate.id,
  });

  const handleContact = useCallback(
    (evt: SyntheticEvent) => {
      evt.preventDefault(); //Blocks navigations on the card
      setOpenDialog(true);
      trackMessagingModalSubmit();
    },
    [trackMessagingModalSubmit, setOpenDialog]
  );

  return (
    <>
      <PepitaButton
        icon={{ name: 'chat' }}
        iconOnly={isCompact}
        onClick={handleContact}
        data-cy="contact-button"
      >
        {trans('lbl_message')}
      </PepitaButton>
      {openDialog && (
        <ContactFormDialog
          data={realEstate}
          agency={agency}
          source={source.label}
          onClose={() => setOpenDialog(false)}
          title={trans('act_contact_advertiser', {
            capitalize: true,
          })}
          textareaPrefilledValue={trans(
            'contact_form_message_placeholder_new',
            {
              capitalize: true,
            }
          )}
          textareaPlaceholder={trans('contact_form_message_label', {
            capitalize: true,
          })}
          onSubmitContact={(contactData) => {
            trackSubmitContact({
              advertiser: realEstate.advertiser,
              contactData,
              visibility: realEstate.visibility || null,
              price: realEstate.price,
            });
          }}
          onShowPhoneClick={(phoneTypes: PhoneTypes) => {
            // toresu track
            trackRealEstatePhoneContact({
              phoneType: phoneTypes,
              source: source.code,
              advertiser: realEstate.advertiser,
              visibility: realEstate.visibility || null,
              price: realEstate.price,
            });
          }}
        />
      )}
    </>
  );
}
