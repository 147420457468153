import { MultimediaTypes } from 'src/types/real-estate';

export const isMultimediaIframe = (
  isInteractive: boolean,
  type: MultimediaTypes
): boolean => {
  if (type === MultimediaTypes.Photos) return false;
  if (
    type === MultimediaTypes.Videos ||
    (type === MultimediaTypes.FloorPlans && isInteractive) ||
    type === MultimediaTypes.VirtualTours ||
    type === MultimediaTypes.PhotoPlan
  ) {
    return true;
  }

  return false;
};
