import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/GeographySearch';
import { searchInfoAtom } from 'src/components/SearchList/atoms/searchInfoAtom';
import { useSearchListParams } from 'src/components/SearchList/hooks/useSearchListParams';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { formatGeographyParams, formatSearchParams } from 'src/tracking/utils';

import { EventName } from '../utils/enums';

import { getSegmentTrackingData } from '../segment/utils';

export const useTracking = () => {
  const track = useTrack();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const searchParams = useSearchListParams();
  const searchInfo = useAtomValue(searchInfoAtom);

  const query = useQueryParams();

  const { user } = useAuthContext();

  const trackLaunchSearch = useCallback(() => {
    track(EventName.LAUNCH_SEARCH, {
      'Is User Logged': Boolean(user),
      ...formatGeographyParams({ searchParams, geographyData }),
      ...formatSearchParams(searchParams),
      'Number Of Results Returned': searchInfo?.resultsCount,
    });
  }, [geographyData, user, searchInfo?.resultsCount, searchParams, track]);

  const trackSearchFilter = useCallback(() => {
    track(EventName.SEARCH_FILTER, {
      ...formatGeographyParams({ searchParams, geographyData }),
      ...formatSearchParams(searchParams),
      'Is User Logged': Boolean(user),
      'Number Of Results Returned': searchInfo?.resultsCount || 0,
    });
  }, [track, searchParams, geographyData, user, searchInfo?.resultsCount]);

  const trackLightListingViewed = useCallback(
    (id: number, section: 'Map' | 'List', index?: number) => {
      const trackingData =
        section === 'List' &&
        getSegmentTrackingData().realEstateList?.find(
          (re) => re['ID Listing'] === id
        );

      track(EventName.LIGHT_LISTING_VIEWED, {
        'ID Listing': id.toString(),
        'Map or List Visualization': section,
        'Page Number': (query.pag as string) || '1',
        'Entry Point': section === 'List' ? 'Lista' : 'Mappa',
        'Index Number': index || trackingData?.['Index Number'] || 0,
      });
    },
    [query.pag, track]
  );

  return {
    trackLaunchSearch,
    trackSearchFilter,
    trackLightListingViewed,
  };
};
