export const getFormattedPrice = (formattedValue?: string) => {
  if (formattedValue) {
    const priceHasSeparator = formattedValue.includes('|') || false;

    if (priceHasSeparator) {
      return formattedValue.split('|').map((el) => el.trim());
    }

    return [formattedValue];
  }

  return undefined;
};
