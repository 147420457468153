import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useFocusRef } from 'src/hooks/useFocusRef';
import { useRealEstateUserNote } from 'src/hooks/useRealEstateUserNote';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';
import {
  PepitaSnackbar,
  PepitaSnackbarAction,
  usePepitaSnackbar,
} from 'src/libs/ui/pepita-snackbar';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import type { RealEstateKey } from 'src/types/real-estate';

import {
  REAL_ESTATE_TRACKING_SECTIONS,
  useRealEstateTracking,
} from 'src/views/RealEstate/hooks/useRealEstateTracking';

import css from './styles.module.scss';

interface SavedNoteDialogProps {
  id: number;
  realEstateKey?: RealEstateKey;
  initialValue: string;
  onClose: () => void;
  onSubmit: (value?: string) => void;
}

export function SavedNoteDialog({
  id,
  realEstateKey,
  initialValue,
  onClose,
  onSubmit,
}: SavedNoteDialogProps) {
  const { trans } = useTranslations();
  const [text, setText] = useState<string>(initialValue);
  const textareaRef = useFocusRef<HTMLTextAreaElement>(null);

  const setSnackBar = usePepitaSnackbar();

  const note = useRealEstateUserNote({
    key: realEstateKey,
    initialValue,
    idGeoHash: undefined,
    saved: true,
    autofetch: false,
  });

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const { trackGA4SaveNote, trackNoteSaved } = useRealEstateTracking({
    realEstateId: id,
    section: REAL_ESTATE_TRACKING_SECTIONS.LIST,
  });

  const handleSubmit = () => {
    note.setState(text);

    if (text) {
      trackGA4SaveNote();
      trackNoteSaved(text, Boolean(note.state));
    }

    onSubmit(text);
    onClose();

    setSnackBar(
      <PepitaSnackbar autoHide={true} duration={2500} variant="success">
        {trans('lbl_success_note')}
      </PepitaSnackbar>
    );
  };

  useEffect(() => {
    setText(note.state || '');
  }, [setText, note.state]);

  const handleText = (value?: string) => {
    note.setState(value ?? '');
    setText(value ?? '');
  };

  const handleDelete = () => {
    const noteDeleted = {
      id,
      value: note.state ?? '',
    };

    setSnackBar(
      <PepitaSnackbar
        variant="info"
        duration={2500}
        action={
          <PepitaSnackbarAction
            onClick={() => {
              handleText(noteDeleted.value);
              onSubmit(noteDeleted.value);
              setSnackBar(null);
            }}
          >
            {trans('act_cancel')}
          </PepitaSnackbarAction>
        }
      >
        {trans('lbl_deleted_note_correctly')}
      </PepitaSnackbar>
    );

    handleText();
    onSubmit();
    onClose();
  };

  return (
    <PepitaDialog
      onClose={() => {
        handleText(note.state);
        onClose();
      }}
      title={trans('lbl_add_note')}
      size={PepitaDialogFrameType.LARGE}
      contentCustomClass={css['sl-savedNoteDialog__content']}
      content={
        <>
          <PepitaTextField
            floatingLabel
            textarea
            maxLength={500}
            name="note"
            label={trans('add_note_placeholder')}
            customClass={css['sl-savedNoteDialog__textarea']}
            placeholder={trans('add_note_placeholder')}
            defaultValue={text}
            onChange={handleChange}
            ref={textareaRef}
          />
          <span
            className={clsx(
              css['sl-savedNoteDialog__counter'],
              text.length === 500 && css['sl-savedNoteDialog__counter--warning']
            )}
          >
            {trans('lbl_count_characters', { params: [text.length, '500'] })}
          </span>
        </>
      }
      footer={
        <>
          {initialValue && (
            <PepitaButton onClick={handleDelete} icon={{ name: 'bin' }}>
              {trans('act_delete')}
            </PepitaButton>
          )}

          <PepitaButton
            variant="accent"
            onClick={handleSubmit}
            customClass={!text ? 'is-disabled' : undefined}
          >
            {trans('act_save')}
          </PepitaButton>
        </>
      }
    />
  );
}
