import { AgencyPhone } from 'src/components/AgencyPhone';
import { PrivatePhone } from 'src/components/PrivatePhone';

import type { AgencyLite, PhoneTypes, Supervisor } from 'src/types/real-estate';

export interface ContentContactCallProps {
  supervisor?: Supervisor;
  agency?: AgencyLite;
  onPhoneContactClick: (phoneType: PhoneTypes) => void;
}

export function ContentContactCall({
  supervisor,
  agency,
  onPhoneContactClick,
}: ContentContactCallProps) {
  const supervisorPhones = supervisor?.phones;
  const agencyPhones = agency?.phones;

  const handlePhoneClick = (sendEvent: boolean, phoneType: PhoneTypes) => {
    //If the array phone length is equal to 1 we send only one event when the user open the modal (real-estate/index.ts)
    if (!sendEvent) {
      return;
    }

    onPhoneContactClick(phoneType);
  };

  if (!supervisor) {
    const sendEvent = agencyPhones && agencyPhones.length > 1;

    return (
      <>
        {agencyPhones?.map((phone, i) => (
          <AgencyPhone
            key={i}
            type={phone.type}
            phoneNumber={phone.value}
            isPhoneVisible
            onPhoneClick={() =>
              handlePhoneClick(Boolean(sendEvent), phone.type)
            }
          />
        ))}
      </>
    );
  }

  if (supervisor.type === 'user') {
    return <PrivatePhone phoneImageUrl={supervisor.phoneUrl} />;
  }

  const sendEvent = supervisorPhones && supervisorPhones.length > 1;

  return (
    <>
      {supervisorPhones?.map((phone, i) => (
        <AgencyPhone
          key={i}
          type={phone.type}
          phoneNumber={phone.value}
          isPhoneVisible
          onPhoneClick={() => handlePhoneClick(Boolean(sendEvent), phone.type)}
        />
      ))}
    </>
  );
}
