import { RealEstateLoweredPrice } from 'src/components/RealEstateLoweredPrice';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { Feature } from 'src/types/real-estate';
import type { Unit as UnitType } from 'src/types/units';

import { getFeatureIcon } from 'src/utils/getFeatureIcon';

import css from './styles.module.scss';

export type Unit = UnitType & {
  matchSearch?: boolean;
  url?: string;
  featureList: Feature[];
};

export function Unit({ unit }: { unit: Unit }) {
  return (
    <>
      <PepitaFigure
        ratio="square"
        figureContent={unit.photo?.urls.small}
        alt={unit.photo?.caption}
        customClass={css['in-listingCardUnit__thumb']}
        lazyLoad={true}
        imageCustomClass={
          isFeatureEnabled('GENERIC_IMAGE')
            ? 'nd-figure__placeholder--generic'
            : undefined
        }
      />
      <div className={css['in-listingCardUnit__content']}>
        <div className={css['in-listingCardUnit__title']}>
          {unit.typology?.name}
        </div>
        <span className={css['in-listingCardUnit__price']}>
          {unit.price?.formattedValue}
        </span>
        {unit.price?.loweredPrice && (
          <RealEstateLoweredPrice
            data={unit.price.loweredPrice}
            isCompact={true}
          />
        )}
        <div className={css['in-listingCardUnit__featureList']}>
          {unit.featureList.map((item, i) => (
            <div key={i} className={css['in-listingCardUnit__featureListItem']}>
              <PepitaIcon
                {...getFeatureIcon(item.type)}
                customClass={'nd-icon--sm'}
              />
              <span>{item.compactLabel || item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
