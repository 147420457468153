import type { RealEstateStatus } from 'src/types/real-estate';

import type { Trans } from 'src/utils/i18nFormatters';

interface GetTransactionDateProps {
  status?: RealEstateStatus;
  transactionDate?: string;
}

export const getTransactionDate = (
  trans: Trans,
  { status, transactionDate }: GetTransactionDateProps
) =>
  status && transactionDate
    ? {
        label: trans(
          status === 'sold'
            ? 'lbl_sold_on' // i18n-extract-keys ["lbl_sold_on"]
            : 'lbl_rented_on', // i18n-extract-keys ["lbl_rented_on"]
          { capitalize: true }
        ),
        date: transactionDate,
      }
    : undefined;
