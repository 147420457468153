import type { PropsWithChildren, SyntheticEvent } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';

import { highlightedRealEstateAtom } from 'src/components/RealEstateSearchMap/atoms/highlightedRealEstateAtom';

import { LOCATION_MARKER } from 'src/constants/real-estate';

import { layoutModeAtom } from 'src/entities/search/components/SearchLayout';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { PepitaMediaObjectInteractive } from 'src/libs/ui/pepita-media-object';

import { getGA4RealEstateListTrackingData } from 'src/tracking/ga/utils/store';
import { RealEstatePositionInfo } from 'src/tracking/utils/enums';

import { openWebUrl } from 'src/utils/getWebUrl';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

interface PropertyProps extends PropsWithChildren {
  enableMouseHoverEvents?: boolean;
}

export function Property({
  enableMouseHoverEvents = false,
  children,
}: PropertyProps) {
  const { realEstate, idGeoHash, realEstateUrl } = useListingCardContext();
  const layoutMode = useAtomValue(layoutModeAtom);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const queryParams = useQueryParams();
  const setHighlighted = useSetAtom(highlightedRealEstateAtom);

  const handleClick = (evt: SyntheticEvent) => {
    // Something blocked the navigation, for example save or hide actions.
    if (evt.defaultPrevented || realEstate.disabled) return;

    evt.preventDefault(); // prevent the title link from opening

    if (realEstateUrl) {
      const realEstateIndex = getGA4RealEstateListTrackingData()?.findIndex(
        (re) => re.realEstateTrackingData?.id === realEstate.id
      );

      if (realEstateIndex !== undefined) {
        localStorage.setItem(
          RealEstatePositionInfo,
          JSON.stringify({
            index: (realEstateIndex + 1).toString(),
            page: queryParams.pag ? queryParams.pag.toString() : '1',
          })
        );
      }

      openWebUrl(realEstateUrl, maxScreenWidthSm ? '_self' : '_blank');
    }
  };

  const handleCardMouseEnter = () => {
    if (!enableMouseHoverEvents || layoutMode !== 'map-besides-the-list') {
      return;
    }

    const location = realEstate.properties[0].location;

    if (
      location &&
      location.latitude &&
      location.longitude &&
      location.marker !== LOCATION_MARKER.NO_MAP
    ) {
      setHighlighted({
        idGeoHash,
        location: {
          lat: location.latitude,
          lng: location.longitude,
        },
      });
    }
  };

  const handleCardMouseLeave = () => {
    if (!enableMouseHoverEvents || layoutMode !== 'map-besides-the-list') {
      return;
    }

    setHighlighted(undefined);
  };

  return (
    <PepitaMediaObjectInteractive
      customClass={clsx(
        css['in-listingCardProperty'],
        realEstate.disabled && 'is-disabled'
      )}
      onClick={handleClick}
      direction="colToRow"
      onMouseEnter={handleCardMouseEnter}
      onMouseLeave={handleCardMouseLeave}
    >
      {children}
    </PepitaMediaObjectInteractive>
  );
}
