import clsx from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { Feature } from 'src/types/real-estate';

import { getFeatureIcon } from 'src/utils/getFeatureIcon';

import css from './styles.module.scss';

interface FeaturesListProps {
  features: Feature[];
}

export function FeatureList({ features }: FeaturesListProps) {
  return (
    <div className={css['in-realEstateSummaryFeatures']}>
      {features.map((item, i) => (
        <div
          key={i}
          className={clsx(
            css['in-realEstateSummaryFeatures__item'],
            item.isHighlighted && 'is-highlighted',
            item.forceLastPosition && 'is-last'
          )}
        >
          <PepitaIcon
            {...getFeatureIcon(item.type, item.isHighlighted)}
            customClass={css['in-realEstateSummaryFeatures__icon']}
          />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
}
