/** This solves a bug with the deeplinking of our app on android. As it seems
 * impossible to avoid opening the app we launch an explicit intent for the
 * web browser
 * @param path
 */
export const getWebUrl = (path: Nullable<string>): Nullable<string> => {
  if (typeof window !== 'undefined') {
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (path && path.startsWith('http') && isAndroid) {
      return `intent://${path}#Intent;S.browser_fallback_url=${path};action=android.intent.action.VIEW;end;`;
    }
  }

  return path ?? null;
};

export const openWebUrl = (url: string, target: '_self' | '_blank') => {
  const webUrl = getWebUrl(url);

  if (target === '_self') {
    location.href = webUrl ?? '';
    // Little trick:
    // if the detailWebPath is not valid for the platform
    // (it relies on intents) we load the detail with the
    // old path
    location.href = url;
  } else {
    const newWindow = window.open(webUrl ?? url, target);

    if (!newWindow) {
      // Fallback for android browsers not supporting intents
      window.open(url);
    }
  }
};
