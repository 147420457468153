import { useTranslations } from '@pepita-react/i18n';

import { getTransactionDate } from './utils';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function TransactionDate() {
  const {
    realEstate: { status, transactionDate: transactionDateStr },
  } = useListingCardContext();
  const { trans } = useTranslations();

  const transactionDate = getTransactionDate(trans, {
    status,
    transactionDate: transactionDateStr,
  });

  if (!transactionDate) return;

  return (
    <p className={css['in-listingCardTransactionDate']}>
      <span className={css['in-listingCardTransactionDate__text']}>
        {`${transactionDate.label} `}
      </span>
      {transactionDate.date}
    </p>
  );
}
