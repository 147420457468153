import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import type { LoweredPrice } from 'src/types/real-estate';

import css from '../index.module.scss';

export function PriceLowered({
  isProjectLike,
  isCompact,
  originalPrice,
  typologiesCount,
}: {
  isProjectLike: boolean;
  isCompact: boolean;
  originalPrice: string;
  typologiesCount: number;
}) {
  const { trans } = useTranslations();

  if (isProjectLike) {
    if (isCompact) {
      return <span>{trans('lowered_price_discounted_price')}</span>;
    }

    return (
      <span>
        {`${trans('lowered_price_discounted_price_typology')} `}
        <span className={css[Style.textBold]}>
          {`${typologiesCount} `}
          {trans('lbl_typology', { count: typologiesCount })}
        </span>
      </span>
    );
  }

  return <span className={css[Style.price]}>{originalPrice}</span>;
}

export function DropdownInfo({
  data,
  loweredBy,
}: {
  data: LoweredPrice;
  loweredBy?: string;
}) {
  const { trans } = useTranslations();

  return (
    <div
      className={clsx(
        'nd-box',
        'nd-box--compact',
        'nd-box--raised',
        css[Style.info]
      )}
      role="tooltip"
    >
      <p className={css[Style.textBold]}>
        {data.typologiesCount ? (
          trans('lowered_price_discounted_price')
        ) : (
          <>
            {`${trans('lowered_price_discounted_price_percent')} ${loweredBy}`}
            <span className={css[Style.discount]}>
              (-{data.discountPercentage}%)
            </span>
          </>
        )}
      </p>
      <p>
        {`${trans('lowered_price_date', {
          params: [data.date],
        })} `}
        (
        {data.passedDays > 0
          ? trans('lbl_day_ago', {
              params: [data.passedDays],
              count: data.passedDays,
            })
          : trans('lbl_today')}
        )
      </p>
    </div>
  );
}

enum Style {
  price = 'in-loweredPrice__price',
  discount = 'in-loweredPrice__discount',
  textBold = 'in-loweredPrice__text--bold',
  info = 'in-loweredPrice__info',
}
