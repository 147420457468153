import type { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { SaveRealEstateDialog } from 'src/components/SaveRealEstateButton/SaveRealEstateDialog';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useRealEstateSave } from 'src/hooks/useRealEstateUserSave';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import {
  REAL_ESTATE_TRACKING_SECTIONS,
  useRealEstateTracking,
} from 'src/views/RealEstate/hooks/useRealEstateTracking';

import { useListingCardContext } from '../../context';

import css from './styles.module.scss';

interface SaveButtonProps {
  customClass?: string;
}

const isSaveWithoutLoginEnabled = isFeatureEnabled(
  'REALESTATE_MODAL_NOT_LOGGED'
);

export function SaveButton({ customClass }: SaveButtonProps) {
  const { trans } = useTranslations();
  const { realEstate, idGeoHash } = useListingCardContext();
  const { saved: isSaved, id, type, dataType, visibility, price } = realEstate;

  const mainProperty = realEstate.properties[0];

  const photos = mainProperty.multimedia?.photos;
  const photoMainUrl = photos?.[0]?.urls.small;

  const saved = useRealEstateSave({
    key: { id, type },
    initialValue: isSaved,
    idGeoHash,
    autofetch: false,
    hasOldSave: !isSaveWithoutLoginEnabled,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { user } = useAuthContext();

  const {
    trackRealEstateSave,
    trackRealEstateSaveGA4,
    trackRealEstateSaveFull,
  } = useRealEstateTracking({
    section: REAL_ESTATE_TRACKING_SECTIONS.LIST,
    realEstateId: id,
  });

  const handleSave = useCallback(
    (fromLoginSuccess?: boolean) => {
      saved.setState(!saved.state);

      const trackingData = {
        saved: !saved.state,
        'Promoted Type Listing': dataType,
        realEstateVisibility: visibility,
        realEstatePrice: price,
      };

      // toresu track
      if (fromLoginSuccess) {
        // only Segment (and mixpanel indirectly) Track because GA4 has already been sent by opening the modal
        trackRealEstateSave(trackingData);
      } else {
        // both GA4 and MixPanel Tracks
        trackRealEstateSaveFull({
          saved: !saved.state,
          modeSelected: 'lista',
        });
      }
    },
    [
      saved,
      dataType,
      visibility,
      price,
      trackRealEstateSave,
      trackRealEstateSaveFull,
    ]
  );

  const handleClick = useCallback(
    (evt: SyntheticEvent) => {
      evt.preventDefault(); //Blocks navigations on the card

      if (!user && isSaveWithoutLoginEnabled) {
        setOpenModal(true);
        // marketing request:
        // only GA4 tracking is sent when open the modal
        // and not when realEstate is actually saved
        trackRealEstateSaveGA4({
          saved: true,
          modeSelected: 'mappa',
        });
      } else {
        handleSave();
      }
    },
    [user, trackRealEstateSaveGA4, handleSave]
  );

  return (
    <>
      {openModal && isSaveWithoutLoginEnabled && (
        <SaveRealEstateDialog
          id={id}
          photo={photoMainUrl}
          price={price}
          features={mainProperty.featureList}
          onClose={() => setOpenModal(false)}
          onLoginSuccess={() => handleSave(true)}
        />
      )}
      <PepitaButton
        customClass={clsx(
          css['in-listingCardSaveButton'],
          customClass,
          saved.state && css['is-fav']
        )}
        iconOnly
        icon={{
          name: 'heart',
          nameAlternate: 'heart--active',
          isAlternate: saved.state,
        }}
        onClick={handleClick}
        aria-label={
          saved.state ? trans('lbl_bookmarked_ad') : trans('act_bookmark_ad')
        }
        data-cy="save-button"
      />
    </>
  );
}
