import type { ListProperty } from 'src/types/real-estate-list';

export const getUnits = (
  properties: ListProperty[],
  hasMainProperty: boolean
) =>
  properties
    .slice(hasMainProperty ? 1 : 0) // Skipping "fake" container property from the list of units
    .map((property) => {
      return {
        price: property.price,
        typology: property.typology,
        matchSearch: property.matchSearch,
        url: property.url,
        photo: property.photo,
        featureList: property.featureList,
      };
    });
