import { LOCATION_MARKER } from 'src/constants/real-estate';

import type { ListPropertyLocation } from 'src/types/real-estate-list';

import type { Trans } from 'src/utils/i18nFormatters';
import { buildStaticMapImageUrl } from 'src/utils/staticMapImage';

export const getMapInfoImage = (
  trans: Trans,
  location?: ListPropertyLocation,
  isCompact?: boolean
) => {
  if (
    !location ||
    !location.latitude ||
    !location.longitude ||
    location.marker === LOCATION_MARKER.NO_MAP
  ) {
    return null;
  }

  return {
    id: null,
    caption: trans('lbl_map'),
    urls: {
      small: buildStaticMapImageUrl(location.latitude, location.longitude, 15, {
        width: isCompact ? 150 : 360,
        height: isCompact ? 150 : 270,
        markerType: location.marker === LOCATION_MARKER.MARKER ? 1 : 2,
        style: 'feature:road|element:labels|visibility:off',
        fillColor: '0x0074c14d',
        strokeColor: '0x0074c1ff',
      }),
    },
  };
};
