import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { fetchUserPreferences } from 'src/api/user/fetchUserPreferences';

import {
  useAtomicStateAtomSetState,
  useAtomicStateAtomValue,
} from 'src/atoms/atomic-state';

import { currentPageAtom } from 'src/components/Pagination/atoms';
import { seoDataAtom } from 'src/components/SEOTags/atoms/SeoDataAtom';

import { useBanners } from 'src/hooks/useBanners';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTrackSearch } from 'src/hooks/useTrackSearch';
import { useIsListingSearchQueryEnabled } from './useIsListingSearchQueryEnabled';
import { useRealEstateSearchUrlResolver } from './useRealEstateSearchUrlResolver';
import { useSearchListParams } from './useSearchListParams';
import { useUpdateSearchInfo } from './useUpdateSearchInfo';

import type { SearchListingData } from 'src/types/real-estate';
import type { RealEstateKey } from 'src/types/real-estate';
import type { SearchParams } from 'src/types/search';

import { http } from 'src/utils/client/http';
import {
  deepEqual,
  forceNumberValuesToString,
  removeEmptyKeys,
} from 'src/utils/object';
import { findRealEstateById } from 'src/utils/real-estate';

export type RealEstateListSearchWithLocalData = SearchListingData & {
  refill: RealEstateKey[];
  waitingForRefill: RealEstateKey[];
};

export const buildRealEstateSearchQueryKey = (
  params: SearchParams & { utm_source?: string },
  pag: number,
  isAuthenticated: boolean
) => {
  return [
    'real-estate-list',
    removeEmptyKeys({
      ...forceNumberValuesToString({ ...params }),
    }),
    String(pag || 1),
    isAuthenticated,
  ];
};

export const useRealEstateSearchQueryKey = () => {
  const { user } = useAuthContext();
  const searchParams = useSearchListParams();
  const pag = useAtomicStateAtomValue(currentPageAtom) || 1;

  return buildRealEstateSearchQueryKey(searchParams, pag, Boolean(user));
};

export const useRealEstateSearch = () => {
  const isClientSearch = useRef(false);

  const { user } = useAuthContext();
  const searchParams = useSearchListParams();
  const pag = useAtomicStateAtomValue(currentPageAtom) || 1;
  const setPag = useAtomicStateAtomSetState(currentPageAtom);

  const isQueryEnabled = useIsListingSearchQueryEnabled();

  const router = useRouter();
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();

  const queryKey = useRealEstateSearchQueryKey();

  const fetchRealEstates = useCallback(
    async (pag: number) => {
      isClientSearch.current = true;

      return http
        .get(`/api-next/search-list/real-estates/`, {
          searchParams: {
            ...searchParams,
            pag,
            paramsCount: Object.keys(queryParams).length,
            path: location.pathname,
          },
        })
        .json<SearchListingData>();
    },
    [searchParams, queryParams]
  );

  const { data, isFetched } = useQuery({
    queryKey,
    queryFn() {
      return fetchRealEstates(pag);
    },
    enabled: isQueryEnabled,
  });

  useLayoutEffect(() => {
    if (!data) return;

    if (data.results.length === 0 && data.count > 0 && pag > 1) {
      setPag(pag - 1);
    }
  }, [data, pag, setPag]);

  useEffect(() => {
    if (!user) return;
    if (!isFetched) return;
    const dataFromCache: SearchListingData | undefined =
      queryClient.getQueryData(queryKey);
    const listings =
      dataFromCache?.results
        // This filtrer help us to handle the mutations on data after the preferences load
        .filter(({ realEstate }) => typeof realEstate.saved !== 'boolean')
        .map(({ realEstate }) => realEstate) || [];

    if (listings.length === 0) return;

    fetchUserPreferences(listings).then((preferences) => {
      const resultsWithPreferences =
        dataFromCache?.results.map((item) => {
          const preference = findRealEstateById(
            item.realEstate.id,
            preferences
          );

          if (!preference) return item;

          return {
            ...item,
            realEstate: {
              ...item.realEstate,
              saved: preference.saved,
              restored: preference.hidden,
            },
          };
        }) || [];

      queryClient.setQueryData(queryKey, {
        ...(dataFromCache || {}),
        results: resultsWithPreferences,
      });
    });
  }, [user, isFetched, queryKey, queryClient]);

  useTrackSearch();

  useRealEstateSearchUrlResolver(isClientSearch.current);

  useBanners();

  useEffect(() => {
    router.beforePopState(({ options }) => {
      /**
       * On back we call getServerSideProps in order to revalidate geography and form state
       */
      options.shallow = false;

      return true;
    });
  }, [router]);

  // Saving some search info
  useUpdateSearchInfo(searchParams, data);

  // Updating seo tags
  const [seoData, setSeoData] = useAtom(seoDataAtom);

  useEffect(() => {
    if (!data) return;

    // we need to check if seoData is deeply equal to result.seoData
    // because we don't want to update seoData if it's the same
    if (!deepEqual(seoData, data.seoData)) {
      setSeoData(data.seoData);
    }

    // App prefix to html tag at page top
    const htmlTag = document.querySelector('html');

    if (htmlTag) {
      htmlTag.setAttribute(
        'prefix',
        data.seoData.facebookSettings?.prefix ?? ''
      );
    }
  }, [data, seoData, setSeoData]);

  return data;
};
