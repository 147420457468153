import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

const isSearchListGridFeaturesEnabled = isFeatureEnabled(
  'SEARCH_LIST_GRID_FEATURES'
);

export function Description() {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const { isCompact, isMosaic, realEstate, hasLowVisibility } =
    useListingCardContext();

  const isDescriptionVisible = !maxScreenWidthSm && !isCompact;
  const isDescriptionV1Visible = isMosaic;
  const isDescriptionV2Visible = !isMosaic && !isSearchListGridFeaturesEnabled;

  if (
    isDescriptionVisible &&
    (isDescriptionV1Visible || isDescriptionV2Visible)
  ) {
    const mainProperty = realEstate.properties[0];

    const caption = !isSearchListGridFeaturesEnabled
      ? mainProperty.caption
      : undefined;
    const description = mainProperty.description;
    const isDescriptionHigh =
      isMosaic &&
      (!isSearchListGridFeaturesEnabled ||
        mainProperty.featureList.length <= 3);
    const isDescriptionHighOnXLCard =
      isMosaic &&
      isSearchListGridFeaturesEnabled &&
      mainProperty.featureList.length <= 4;
    const isDescriptionCompact = !isMosaic && !isSearchListGridFeaturesEnabled;

    if (!caption && !description) return;

    return (
      <div
        className={clsx(
          css['in-listingCardDescription'],
          isDescriptionHigh && css['is-high'],
          isDescriptionHighOnXLCard && css['is-highOnXLCard'],
          isDescriptionCompact && css['is-compact'],
          hasLowVisibility && css['has-lowVisibility']
        )}
      >
        {caption && (
          <div className={css['in-listingCardDescription__caption']}>
            {caption}
          </div>
        )}
        {description}
      </div>
    );
  }
}
