import { useMemo, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { useSimpleForm } from 'src/hooks/useSimpleForm';
import type { SavedFormData } from './useSaveRealEstateSchema';
import { useSaveRealEstateSchema } from './useSaveRealEstateSchema';

import { setGA4trackingData } from 'src/tracking/ga/utils/store';
import { hashEmail, storeLastEmailAndPhone } from 'src/tracking/utils';

import { http } from 'src/utils/client/http';

export const useSaveRealEstateForm = ({
  realEstateId,
  onCloseSave,
  onLoginSuccess,
}: {
  realEstateId: number;
  onCloseSave: () => void;
  onLoginSuccess?: () => void;
}) => {
  const { trans } = useTranslations();
  const schema = useSaveRealEstateSchema();
  const [sendMail, setSendMail] = useState<boolean>(false);
  const initialData = useMemo(() => ({}), []);
  const setAuthModal = useSetAtom(authModalAtom);
  const { user } = useAuthContext();

  const handleAuthModal = () => {
    setAuthModal({ open: false });
    onCloseSave();
  };

  const form = useSimpleForm<
    SavedFormData,
    { result: 'SENT' | 'NOT_SENT' | 'ERROR' }
  >({
    schema,
    initialData,
    onSubmit(data) {
      return http
        .post('/invia_email_salva_annuncio.php', {
          form: {
            email: data.email,
            idAnnuncio: realEstateId,
          },
        })
        .json();
    },
    onSuccess(savedFormData, submitResponse) {
      if (!user) {
        setGA4trackingData({
          user: {
            user_hashedemail: hashEmail(savedFormData.email),
          },
        });
        storeLastEmailAndPhone({ email: savedFormData.email });
      }

      if (submitResponse.result === 'SENT') {
        setSendMail(true);
      } else if (submitResponse.result === 'NOT_SENT') {
        setAuthModal({
          open: true,
          onClose: handleAuthModal,
          onSuccess: () => {
            onLoginSuccess?.();
            handleAuthModal();
          },
          data: {
            email: savedFormData.email,
            additionalTrackingData: {
              'Entry Point': 'Modale Salva Annuncio',
            },
            alertMessage: trans('signin_to_save_ad'),
            alertMessageType: 'warning',
          },
          showAdvantageInfo: false,
          buttonText: trans('save_ad'),
        });
      }

      return '';
    },
    onError() {
      return trans('lbl_generic_error');
    },
  });

  return { form, sendMail, setSendMail };
};
