import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import {
  PepitaButton,
  type PepitaButtonProps,
} from 'src/libs/ui/pepita-button';

import { ContactCallDialog } from './ContactCallDialog';
import type { ContentContactCallProps } from './ContentContactCall';

interface ContactCallButtonProps extends ContentContactCallProps {
  onModalOpen: () => void;
  isCompactButton?: boolean;
  iconOnly?: boolean;
  variant?: PepitaButtonProps['variant'] | 'default';
}

export function ContactCallButton({
  supervisor,
  agency,
  isCompactButton = false,
  onModalOpen,
  onPhoneContactClick,
  iconOnly = false,
  variant = 'link',
}: ContactCallButtonProps) {
  const { trans } = useTranslations();
  const [openDialog, setOpenDialog] = useState(false);

  const handleCall = (evt: SyntheticEvent) => {
    evt.preventDefault();
    onModalOpen();
    setOpenDialog(true);
  };

  return (
    <>
      <PepitaButton
        variant={variant === 'default' ? undefined : variant}
        icon={{ name: 'phone' }}
        onClick={handleCall}
        small={isCompactButton}
        iconOnly={iconOnly}
      >
        {trans('act_call')}
      </PepitaButton>
      {openDialog && (
        <ContactCallDialog
          onClose={() => setOpenDialog(false)}
          supervisor={supervisor}
          agency={agency}
          onPhoneContactClick={onPhoneContactClick}
        />
      )}
    </>
  );
}
