import type { MouseEvent, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import type { RealEstateBadgeProps } from 'src/components/RealEstateBadge';
import { RealEstateBadge } from 'src/components/RealEstateBadge';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';

import type { Multimedia, RealEstateStatus } from 'src/types/real-estate';
import { MultimediaTypes } from 'src/types/real-estate';
import type { MultimediaLite } from 'src/types/real-estate-lite';

import { isMultimediaIframe } from 'src/utils/isMultimediaIframe';

import generic_placeholder from '/src/images/figure_placeholder.svg';
import placeholder from '/src/images/immo_placeholder.svg';

export * from './EmptyState';

import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import type { PepitaFigureProps } from 'src/libs/ui/pepita-figure';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaSlideShow } from 'src/libs/ui/pepita-slideshow';

import type { EventName } from 'src/tracking/utils/enums';

import css from './styles.module.scss';

interface OnSlideChangeParams {
  activeSlide?: number;
  activeImage: any;
}

export type PhotosProps = {
  emptyState?: ReactNode;
  width?: number;
  height?: number;
  customClass?: string;
  imageCustomClass?: string;
  hasCustomRatio?: boolean;
  ratio?: PepitaFigureProps['ratio'];
  photoMainUrl?: string;
  imageSize?: 'small' | 'medium';
  visibility?: RealEstateBadgeProps['tier'];
  overlay?: boolean;
  brandNew?: boolean;
  extraMultimedia?: MultimediaLite;
  multimedia: MultimediaLite | Multimedia;
  lazyLoad?: boolean | 'only-carousel';
  onClick?: (slide?: number, eventName?: EventName) => void;
  onSlideChange?: (params: OnSlideChangeParams) => void;
  onThirdSlideChangeOnce?: () => void;
  type: MultimediaTypes;
  defaultSlide?: number;
  hideArrows?: boolean;
  realEstateStatus?: RealEstateStatus;
  disabled?: boolean;
  isListingDetail?: boolean;
  expandButtonFullScreen?: boolean;
};

export function Photos({
  emptyState,
  width,
  height,
  customClass,
  imageCustomClass,
  hasCustomRatio,
  ratio,
  photoMainUrl,
  imageSize = 'medium',
  visibility,
  overlay,
  brandNew,
  extraMultimedia,
  multimedia,
  lazyLoad = false,
  type,
  hideArrows: hideSlideShowArrows,
  onClick,
  defaultSlide = 0,
  onSlideChange,
  onThirdSlideChangeOnce,
  realEstateStatus,
  disabled,
  isListingDetail,
  expandButtonFullScreen,
}: PhotosProps) {
  const images = multimedia[type];
  const extraImages = extraMultimedia?.[type];
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const imagesWithExtras = useMemo(() => {
    const result = [...(images || [])]; // Insert the images

    if (!images?.length && extraImages?.length) {
      // Add a placeholder image only when there are not images and when there are extraImages
      result.push({
        id: null,
        caption: '',
        urls: {
          small: isFeatureEnabled('GENERIC_IMAGE')
            ? generic_placeholder
            : placeholder,
        },
      });
    }

    // Add the extra images
    result.push(...(extraImages || []));

    return result;
  }, [images, extraImages]);

  const { trans } = useTranslations();
  const ref = useRef<HTMLElement>(null);
  const [photoCount, setPhotoCount] = useState<number>(
    defaultSlide === 0 ? 1 : defaultSlide
  );
  const [showCarousel, setShowCarousel] = useState(lazyLoad === false);
  const hasThirdSlideChanged = useRef<boolean>(false);

  const isVirtualOrPlanimetry =
    type === MultimediaTypes.FloorPlans ||
    type === MultimediaTypes.VirtualTours;

  useIntersectionObserver({
    ref,
    onChange: setShowCarousel,
    root: 'document',
    enabled: !showCarousel && imagesWithExtras.length > 1,
    margin: () => 200,
  });

  let content: ReactNode = photoMainUrl || emptyState;

  const [activeSlide, setActiveSlide] = useState<number>();
  const slideChangeTimeoutRef = useRef<NodeJS.Timeout>();

  const handleOnSlideChange = (newActiveSlide: number) => {
    if (slideChangeTimeoutRef.current) {
      clearTimeout(slideChangeTimeoutRef.current);
    }

    slideChangeTimeoutRef.current = setTimeout(() => {
      setActiveSlide(newActiveSlide);
      const isLastPhoto = newActiveSlide === imagesWithExtras.length - 1;

      onSlideChange &&
        onSlideChange({
          activeImage: imagesWithExtras[newActiveSlide],
          activeSlide: newActiveSlide,
        });

      if (
        newActiveSlide === 2 &&
        onThirdSlideChangeOnce &&
        !hasThirdSlideChanged.current
      ) {
        onThirdSlideChangeOnce();
        hasThirdSlideChanged.current = true;
      }

      /** extra images at the end of the gallery should not be counted */
      if (isLastPhoto && extraImages?.length) {
        setPhotoCount(newActiveSlide);
      } else {
        setPhotoCount(newActiveSlide + 1);
      }
    }, 250);
  };

  if (showCarousel && imagesWithExtras.length > 1) {
    const handleArrowClick = (evt: MouseEvent<HTMLButtonElement>) => {
      // Blocks the click listener on SearchList
      evt.stopPropagation();
    };

    content = (
      <PepitaSlideShow
        lazyLoad
        onPrevClick={handleArrowClick}
        onNextClick={handleArrowClick}
        defaultSlide={defaultSlide}
        onSlideChange={handleOnSlideChange}
        noArrows={hideSlideShowArrows}
      >
        {imagesWithExtras.map((item, i) =>
          !isMultimediaIframe(item.interactive, type) ? (
            <PepitaSlideShow.Image
              src={item.urls?.[imageSize]}
              alt={item.caption}
              key={i}
              width={width || 360}
              height={height || 270}
              customClass={item.customClass || null}
            />
          ) : (
            <PepitaSlideShow.Iframe
              title={item.caption ? item.caption : ''}
              src={item.url}
              key={i}
              customClass={css['in-photo__iframe']}
              slideProps={{ 'data-text': trans('act_open') }}
            />
          )
        )}
      </PepitaSlideShow>
    );
  }

  const isSingleIframe =
    imagesWithExtras.length === 1 &&
    isMultimediaIframe(imagesWithExtras[0].interactive, type);

  if (isSingleIframe) {
    content = (
      <iframe
        width={width || 314}
        height={height || 236}
        loading="lazy"
        src={imagesWithExtras[0].url}
        title={imagesWithExtras[0].caption ?? ''}
        allow="encrypted-media; xr-spatial-tracking;"
        allowFullScreen
        className="nd-ratio__img"
      />
    );
  }

  return (
    <PepitaFigure
      as={images?.length === 1 ? 'figure' : 'div'}
      customClass={clsx(
        css['in-photo'],
        isVirtualOrPlanimetry && css['in-photo--customBorder'],
        isSingleIframe && css['in-photo__iframe'],
        customClass
      )}
      imageCustomClass={clsx(imageCustomClass)}
      ratio={hasCustomRatio ? 'custom' : ratio ?? 'standard'}
      figureContent={content}
      width={width || 314}
      height={height || 236}
      alt={images?.length === 1 && images[0].caption ? images[0].caption : ''}
      lazyLoad={lazyLoad === true}
      fadeIn
      ref={ref}
      onClick={() => {
        onClick?.(activeSlide);
      }}
      data-text={isSingleIframe ? trans('act_open') : null}
    >
      {expandButtonFullScreen && (
        <PepitaButton
          customClass={css['in-photo__expand']}
          icon={{
            name: 'expand',
          }}
          iconOnly={maxScreenWidthSm}
        >
          {trans('act_map_grow', {
            capitalize: true,
          })}
        </PepitaButton>
      )}
      {overlay &&
      (multimedia.photos?.length || multimedia.virtualTours?.length) ? (
        <div className={clsx(css['in-photo__overlay'], css['in-photo__media'])}>
          {multimedia.virtualTours &&
            multimedia.virtualTours.length > 0 &&
            !isListingDetail && (
              <PepitaBadge
                variant="reversed"
                onlyIcon={true}
                customClass={css['in-photo__mediaTag']}
                icon={{
                  name: 'tour',
                }}
              />
            )}

          {multimedia.photos && multimedia.photos.length > 0 && (
            <PepitaBadge
              variant="reversed"
              customClass={css['in-photo__mediaTag']}
            >
              {photoCount}/{multimedia.photos.length}
            </PepitaBadge>
          )}
        </div>
      ) : null}
      {disabled && (
        <PepitaBadge
          customClass={clsx(css['in-photo__badge'], css['not-available'])}
          position="topRight"
        >
          {trans('lbl_not_available')}
        </PepitaBadge>
      )}

      {brandNew && (
        <PepitaBadge
          position="topRight"
          variant="brand"
          customClass={css['in-photo__badge']}
        >
          {trans('lbl_new')}
        </PepitaBadge>
      )}
      {realEstateStatus && (
        <PepitaBadge
          variant="promotion"
          position="topRight"
          customClass={css['in-photo__badge']}
        >
          {trans(
            realEstateStatus === 'sold' ? 'lbl_sold' : 'lbl_rented' // i18n-extract-keys ["lbl_sold", "lbl_rented"]
          )}
        </PepitaBadge>
      )}
      {visibility && (
        <RealEstateBadge tier={visibility} position="bottomLeft" />
      )}
    </PepitaFigure>
  );
}
