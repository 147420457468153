import { type SyntheticEvent, useState } from 'react';
import spriteListingDetail from '@pepita-fe/sprite-listing-detail/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { RealEstateKey } from 'src/types/real-estate';

import { SavedNoteDialog } from 'src/views/SavedListings/components/SavedNoteDialog';

import css from './styles.module.scss';

interface NoteButtonProps {
  id: number;
  initialValue: string | undefined;
  realEstateKey: RealEstateKey | undefined;
  onSubmit: (id: number, value?: string) => void;
  disabled: boolean;
}

export function NoteButton({
  id,
  initialValue,
  realEstateKey,
  onSubmit,
  disabled = false,
}: NoteButtonProps) {
  const { trans } = useTranslations();
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);

  return (
    <>
      <PepitaButton
        customClass={clsx(
          css['in-listingCardNoteButton__button'],
          disabled && css['is-disabled']
        )}
        iconOnly
        icon={{
          name: 'write',
          spriteUrl: spriteListingDetail,
        }}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          setShowNoteDialog(true);
        }}
        aria-label={trans('act_add_notes')}
      >
        {trans('lbl_note')}
      </PepitaButton>

      {showNoteDialog && (
        <SavedNoteDialog
          onClose={() => {
            setShowNoteDialog(false);
          }}
          id={id}
          realEstateKey={realEstateKey}
          initialValue={initialValue || ''}
          onSubmit={(value?: string) => {
            onSubmit(id, value);
          }}
        />
      )}
    </>
  );
}
