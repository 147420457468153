import { replaceUrl } from './url';

export enum PageFragmentPrefix {
  ACTIVE_IMAGE = 'activeImage',
  GEO_HASH_PARAM = 'geohash',
}

export const addPageFragment = (fragment: string) => {
  const { pathname, search } = window.location;

  replaceUrl(`${pathname + search}#${fragment}`);
};

export const removePageFragment = () => {
  if (window.location.hash === '') return;

  const { pathname, search } = window.location;

  replaceUrl(pathname + search);
};

export const removePageFragmentWithPrefix = (prefix: PageFragmentPrefix) => {
  if (
    window.location.hash === '' ||
    !window.location.hash.startsWith(`#${prefix}`)
  ) {
    return;
  }

  const { pathname, search } = window.location;

  replaceUrl(pathname + search);
};
