import { SniperLinkContent } from '@indomita-react/sniper-link';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { SniperLinkButtonWrapper } from 'src/components/SniperLinkButtonWrapper';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { useSaveRealEstateForm } from '../hooks/useSaveRealEstateForm';

import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

import type { Price } from 'src/types/real-estate';
import type { Feature } from 'src/types/real-estate';

import { RealEstateSummary } from '../RealEstateSummary';

import { Form } from './Form';

import css from './styles.module.scss';

interface SaveRealEstateDialogProps {
  id: number;
  photo?: string;
  price?: Price;
  features: Feature[];
  onClose: () => void;
  onLoginSuccess?: () => void;
}

export function SaveRealEstateDialog({
  id,
  photo,
  price,
  features,
  onClose,
  onLoginSuccess,
}: SaveRealEstateDialogProps) {
  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');
  const { trans } = useTranslations();
  const { form, sendMail } = useSaveRealEstateForm({
    realEstateId: id,
    onCloseSave: onClose,
    onLoginSuccess,
  });

  return (
    <>
      <PepitaDialog
        onClose={onClose}
        title={trans('save_ad')}
        frameCustomClass={clsx(
          css['in-saveRealEstateDialog'],
          'cy-save-card-dialog'
        )}
        contentCustomClass={
          !sendMail ? css['in-saveRealEstateDialog__boxedContent'] : undefined
        }
        size={PepitaDialogFrameType.BOTTOMSHEET}
        content={
          sendMail ? (
            <SniperLinkContent text={trans('check_email_save_ad')} />
          ) : (
            <>
              <RealEstateSummary
                photo={photo}
                price={price}
                features={features}
              />
              <Form form={form} />
            </>
          )
        }
        footer={
          sendMail && (
            <SniperLinkButtonWrapper
              email={form.data.email}
              domainName={domainName}
              onClick={onClose}
            />
          )
        }
      />
    </>
  );
}
