import { useEffect } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { fetchUserPreferences } from 'src/api/user/fetchUserPreferences';

import { useLoginWall } from './useLoginWall';
import { useRealEstateSave } from './useRealEstateUserSave';

import type { RealEstateKey } from 'src/types/real-estate';

import { http } from 'src/utils/client/http';

import { notesAtom } from 'src/views/RealEstate/atoms/notesAtom';

function getListingType(data: RealEstateKey) {
  return data.type === 'project' ? 'progetti' : 'annunci';
}

function updateRealEstateUserNote(
  listing: RealEstateKey,
  value: Nullable<string>
): Promise<any> {
  const url = `/api/utente/${getListingType(listing)}/note/${listing.id}`;

  if (value) {
    return http
      .post(url, {
        form: {
          nota: value,
        },
      })
      .raw();
  }

  return http.delete(url).raw();
}

type Params = {
  key?: RealEstateKey;
  initialValue?: string;
  idGeoHash?: string;
  saved: boolean;
  autofetch: boolean;
};

export const useRealEstateUserNote = ({
  key,
  initialValue,
  idGeoHash,
  saved,
  autofetch,
}: Params) => {
  const { user } = useAuthContext();

  const [notes, setNotes] = useAtom(notesAtom);

  /**
   * We set the note value following this order:
   * - the last user interaction with this real estate the note action
   * - the note value sent from the data api
   * - we fetch the value from an api
   */
  useEffect(() => {
    if (!key) return;
    const note = notes.get(key.id);

    // The user had already updated the note so the effect
    // should do nothing, maintaining the current client value
    if (typeof note === 'string') return;

    // when the user is not authenticated the note value is undefined
    if (!user) return;

    // the note value sent from the data api
    if (typeof initialValue === 'string') {
      setNotes(notes.set(key.id, initialValue));

      return;
    }

    if (autofetch) {
      fetchUserPreferences([key]).then(([preferences]) => {
        setNotes(notes.set(key.id, preferences.note));
      });
    }
  }, [initialValue, key, user, autofetch, setNotes, notes]);

  const login = useLoginWall();
  const save = useRealEstateSave({
    key,
    initialValue: saved,
    idGeoHash,
    showSnackbar: false,
    autofetch,
  });

  const api = (value: Nullable<string>) => {
    return login().then(() => {
      !save.state && save.setState(true);

      return updateRealEstateUserNote(key as RealEstateKey, value);
    });
  };

  const mutation = useMutation<null, null, Nullable<string>, any>({
    mutationFn: api,
    onMutate(value) {
      key && setNotes(notes.set(key.id, value ?? undefined));

      return { value };
    },
    onError(_, __, { prev }) {
      key && setNotes(notes.set(key.id, prev));
    },
    onSettled() {
      if (!user) return;
    },
  });

  return {
    state: key ? notes.get(key.id) : undefined,
    setState: mutation.mutate,
  };
};
