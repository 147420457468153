import clsx from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { getFeatureIcon } from 'src/utils/getFeatureIcon';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

const isSearchListGridFeaturesEnabled = isFeatureEnabled(
  'SEARCH_LIST_GRID_FEATURES'
);

export function FeatureList() {
  const { realEstate, isCompact, hasLowVisibility } = useListingCardContext();

  const mainProperty = realEstate.properties[0];

  const hasCompactStyle = isCompact || !isSearchListGridFeaturesEnabled;

  return (
    <div
      className={clsx(
        css['in-listingCardFeatureList'],
        hasCompactStyle && css['is-compact'],
        !isSearchListGridFeaturesEnabled && css['is-row'],
        !isCompact && hasLowVisibility && css['has-lowVisibility']
      )}
    >
      {mainProperty.featureList.map((item, i) => (
        <div
          key={i}
          className={clsx(
            css['in-listingCardFeatureList__item'],
            item.isHighlighted && 'is-highlighted',
            item.forceLastPosition && 'is-last'
          )}
        >
          <PepitaIcon
            {...getFeatureIcon(item.type, item.isHighlighted)}
            customClass={css['in-listingCardFeatureList__icon']}
          />

          <span>
            {hasCompactStyle && item.compactLabel
              ? item.compactLabel
              : item.label}
          </span>
        </div>
      ))}
    </div>
  );
}
