import { capitalize, useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObject,
  PepitaMediaObjectContent,
} from 'src/libs/ui/pepita-media-object';

import type { Price } from 'src/types/real-estate';
import type { Feature } from 'src/types/real-estate';

import { lowerize } from 'src/utils/string';

import { FeatureList } from './FeatureList';

import css from './styles.module.scss';

interface RealEstateSummaryProps {
  photo?: string;
  price?: Price;
  features: Feature[];
}

export function RealEstateSummary({
  features,
  photo,
  price,
}: RealEstateSummaryProps) {
  const { trans } = useTranslations();

  const priceText = price?.minValue
    ? `${lowerize(trans('price_range_label_from'))} ${price.minValue}`
    : capitalize(price?.formattedValue);

  return (
    <div className={css['in-realEstateSummary']}>
      <p className={css['in-realEstateSummary__infoText']}>
        {trans('save_ad_info_message')}
      </p>
      <PepitaMediaObject
        direction="col"
        customClass={css['in-realEstateSummary__card']}
      >
        <PepitaFigure
          figureContent={photo}
          ratio="wide"
          imageCustomClass={clsx(
            css['in-realEstateSummary__figure'],
            isFeatureEnabled('GENERIC_IMAGE')
              ? 'nd-figure__placeholder--generic'
              : undefined
          )}
        />
        <PepitaMediaObjectContent>
          <span className={css['in-realEstateSummary__price']}>
            {priceText}
          </span>
          <FeatureList features={features} />
        </PepitaMediaObjectContent>
      </PepitaMediaObject>
    </div>
  );
}
