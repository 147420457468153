import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { PepitaMediaObjectContent } from 'src/libs/ui/pepita-media-object';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function PropertyContent({ children }: PropsWithChildren) {
  const { isCompact, realEstate } = useListingCardContext();

  return (
    <PepitaMediaObjectContent
      customClass={clsx(
        css['in-listingCardPropertyContent'],
        realEstate.disabled && 'is-disabled',
        isCompact && 'is-small'
      )}
    >
      {children}
    </PepitaMediaObjectContent>
  );
}
