import clsx from 'clsx';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function Title() {
  const { realEstate, realEstateUrl, isCompact, isMosaic } =
    useListingCardContext();

  return (
    <a
      href={realEstateUrl}
      title={realEstate.title}
      className={clsx(css['in-listingCardTitle'], isMosaic && 'is-spaced')}
    >
      {isCompact ? realEstate.properties[0].typology.name : realEstate.title}
    </a>
  );
}
