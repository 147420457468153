import { http } from '@pepita/http';

import type { Listing } from 'src/types/listing-search';
import type { RealEstate, RealEstateKey } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateUserPreferences } from 'src/types/user-preferences';

import { pick } from 'src/utils/object';

export const fetchUserPreferences = (
  listings: (RealEstate | ListRealEstate | Listing | RealEstateKey)[]
) => {
  const keys = listings.map((item) => pick(item, 'id', 'type'));

  const request = http.get(`/api-next/user/preferences/`, {
    searchParams: {
      listings: JSON.stringify(keys),
    },
  });

  return request.json<RealEstateUserPreferences[]>();
};
