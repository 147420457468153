import type { Result } from './hooks/useUpdateSearchInfo';

import type { GeographySearch } from 'src/libs/geography';

export const getIsEditing = (
  searchParamsAreChanged: boolean,
  firstRedirect: boolean,
  prevIsEditing: Optional<boolean>,
  prevSearchId: Optional<string>,
  isAuthenticated: boolean,
  geographyData: GeographySearch | null,
  neverSaved: boolean,
  hasMapCustomPosition: boolean
) => {
  if (!geographyData) {
    if (neverSaved) {
      return false;
    }

    return hasMapCustomPosition;
  }

  if (searchParamsAreChanged) {
    if (firstRedirect) {
      return prevIsEditing;
    }

    if (prevSearchId) {
      return isAuthenticated;
    }
  }

  return prevIsEditing;
};

export const getSearchName = (result: Result | undefined) => {
  if (!result) return '';

  if ('seoData' in result) {
    return result.seoData.searchName || '';
  }

  return '';
};
