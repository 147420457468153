import { createContext, useContext } from 'react';

import type { ListRealEstate } from 'src/types/real-estate-list';

type ListingCardContextValue = {
  idGeoHash: string;
  realEstate: ListRealEstate;
  realEstateUrl: string;
  isCompact: boolean;
  isMosaic: boolean;
  hasLowVisibility: boolean;
};

export const ListingCardContext = createContext<ListingCardContextValue | null>(
  null
);

export const useListingCardContext = () => {
  const context = useContext(ListingCardContext);

  if (!context) {
    throw new Error(
      'useListingCardContext must be used within a ListingCard component'
    );
  }

  return context;
};
