import { useQuery } from '@tanstack/react-query';

import type { SearchParams } from 'src/types/search';

import { http } from 'src/utils/client/http';
interface SearchListRedirectResponse {
  url: string;
}

export const useSearchListRedirect = ({
  params,
  pag,
  enabled = true,
}: {
  params: SearchParams;
  pag: number;
  enabled: boolean;
}) => {
  const { data: redirectUrl, isFetching } = useQuery({
    queryKey: [`real-estate-list-redirect`, params, pag],
    queryFn: () =>
      http
        .get('/api-next/search-list/url-resolver/', {
          searchParams: { ...params, pag },
        })
        .json<SearchListRedirectResponse>()
        .then((res) => res.url),
    enabled,
  });

  return { redirectUrl, isFetching };
};
