import type { SyntheticEvent } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function AgencyLogo() {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const { realEstate, isCompact } = useListingCardContext();

  const agency = realEstate.advertiser.agency;
  const isAgencyLogoVisible =
    agency && !isCompact && !maxScreenWidthSm && agency.showLogo;

  if (!isAgencyLogoVisible) return;

  const agencyUrl = agency.agencyUrl;
  const agencyType = agency.type;
  const largeImageUrl = agency.imageUrls?.large;
  const imageAlt = agency.displayName;

  const isInteractive =
    agencyUrl && isFeatureEnabled('LINK_AGENCY_LOGO_ON_SEARCH_LIST');

  const onClick = (evt: SyntheticEvent) => {
    if (!isInteractive) {
      return;
    }

    evt.preventDefault(); //Blocks navigations on the card
    window.open(`${agencyUrl}${'?entryPoint=list-card'}`, '_blank');
  };

  return (
    <PepitaFigure
      customClass={clsx(
        css['in-listingCardAgencyLogo'],
        !largeImageUrl && css['in-listingCardAgencyLogo--placeholder'],
        isInteractive && 'is-interactive'
      )}
      ratio="custom"
      imageCustomClass={clsx(
        largeImageUrl && css['in-listingCardAgencyLogo__image']
      )}
      alt={imageAlt}
      figureContent={
        largeImageUrl || <AgencyPlaceholder agencyType={agencyType} compact />
      }
      width={135}
      height={35}
      onClick={onClick}
    />
  );
}
