import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { capitalize } from 'src/utils/string';

import css from './styles.module.scss';

export function PhotosEmptyStateWithCallToAction({
  customClass,
  onClick,
  numberOfPhotos,
}: {
  customClass?: string;
  onClick: () => void;
  numberOfPhotos?: number;
}) {
  const { trans } = useTranslations();

  return (
    <div className={clsx(css['in-photo__empty'], customClass)}>
      <PepitaIcon name={'gallery'} customClass={css['in-photo__emptyIcon']} />
      <p className={css['in-photo__title']}>
        {capitalize(
          numberOfPhotos
            ? trans('ad_with_less_photo')
            : trans('ad_with_no_photo')
        )}
      </p>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <a
        role="button"
        tabIndex={0}
        data-thread-url="threadUrl"
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        {capitalize(trans('act_ask_for_photos'))}
      </a>
      {/* eslint-enable jsx-a11y/click-events-have-key-events */}
    </div>
  );
}
