import { useEffect, useRef } from 'react';

import type {
  AtomicStateAtomRef,
  AtomicStateStateAtom,
} from 'src/atoms/atomic-state';
import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { deepEqual } from 'src/utils/object';

export const useOnDeepEqualAtomChange = <S,>(
  atom: AtomicStateAtomRef<AtomicStateStateAtom<S>>,
  onChange: () => void
) => {
  const atomValue = useAtomicStateAtomValue(atom);
  const prevAtomValue = useRef(atomValue);

  useEffect(() => {
    if (!deepEqual(prevAtomValue.current, atomValue)) {
      prevAtomValue.current = atomValue;
      onChange();
    }
  }, [onChange, atomValue]);
};
