import { useCallback, useMemo, useRef } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { useUpdateStripeVisibleSlidesCount } from 'src/entities/search/components/SearchLayout';

import type { PepitaStripeHandle } from 'src/libs/ui/pepita-stripe';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import { getUnits } from './utils';

import { useListingCardContext } from '../context';

import { ShowAll } from './ShowAll';
import { Unit } from './Unit';

import css from './styles.module.scss';

interface UnitsProps {
  customClass?: string;
}

export function Units({ customClass }: UnitsProps) {
  const { realEstate, realEstateUrl } = useListingCardContext();
  const stripeRef = useRef<PepitaStripeHandle>(null);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  useUpdateStripeVisibleSlidesCount(stripeRef);

  const showAllUrl = realEstateUrl;

  const { properties, isProjectLike, hasMainProperty } = realEstate;

  const units = getUnits(properties, hasMainProperty);
  const isUnitsSliderVisible =
    isProjectLike && properties.length > 1 && !maxScreenWidthSm;
  const maxVisibleUnits = 5;
  const showAllBox = units.length > maxVisibleUnits && showAllUrl;

  const handleShowAllClick = useCallback(() => {
    window.open(showAllUrl, '_blank');
  }, [showAllUrl]);

  const slides = useMemo(() => {
    // reorder by placing on top the units that match search
    const sortedUnits = units.sort((el1, el2) => {
      if (el1.matchSearch && !el2.matchSearch) {
        return -1;
      } else if (!el1.matchSearch && el2.matchSearch) {
        return 1;
      }

      return 0;
    });
    const unitsList = sortedUnits.slice(0, maxVisibleUnits);

    return unitsList.map((unit, i) => {
      const handleUnitClick = (url?: string) => {
        if (!url) return;
        window.open(url, '_blank');
      };

      const unitProps = unit.url
        ? {
            onClick: () => handleUnitClick(unit.url),
            onKeyDown: () => handleUnitClick(unit.url),
          }
        : {};

      return (
        <PepitaStripe.Slide
          key={i}
          customClass={css['in-listingCardUnits__item']}
        >
          <div className={css['in-listingCardUnits__card']} {...unitProps}>
            <Unit unit={unit} />
          </div>
        </PepitaStripe.Slide>
      );
    });
  }, [units]);

  if (!isUnitsSliderVisible) return;

  if (showAllBox) {
    slides.push(
      <PepitaStripe.Slide
        key={maxVisibleUnits + 1}
        customClass={css['in-listingCardUnits__item']}
      >
        <div
          className={clsx(
            css['in-listingCardUnits__card'],
            css['in-listingCardUnits__card--showAll']
          )}
          onClick={handleShowAllClick}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleShowAllClick();
            }
          }}
        >
          <ShowAll units={units} />
        </div>
      </PepitaStripe.Slide>
    );
  }

  return (
    <PepitaStripe
      customClass={clsx(
        css['in-listingCardUnits'],
        realEstate.disabled && css['is-disabled'],
        customClass
      )}
      innerArrow={true}
      ref={stripeRef}
      spaced
    >
      {slides}
    </PepitaStripe>
  );
}
