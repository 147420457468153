import { useAuthContext } from '@indomita-react/auth-provider';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import type { Language } from 'src/types/translations';

import { getAgencyUrl } from 'src/utils/getAgencySignupUrl';

export const useLoginWall = () => {
  const { user } = useAuthContext();
  const { locale, defaultLocale } = useRouter() as {
    defaultLocale: Language;
    locale: Language;
  };
  const setAuthModal = useSetAtom(authModalAtom);

  function login() {
    if (user) {
      return Promise.resolve({ fromLogin: false });
    }

    return new Promise<{ fromLogin: boolean }>((resolve, reject) => {
      setAuthModal({
        open: true,
        onClose: () => {
          setAuthModal({ open: false });
          reject();
        },
        onSuccess: (action, service) => {
          if (
            ['apple', 'google', 'facebook'].some(
              (social) => service?.includes(social)
            ) ||
            action === 'login' ||
            action === 'signup'
          ) {
            setAuthModal({ open: false });
            resolve({ fromLogin: true });
          }
        },
        showAdvantageInfo: true,
        contentType: 'default',
        agencySignUp: getAgencyUrl(locale, defaultLocale),
      });
    });
  }

  return login;
};
