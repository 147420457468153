import { useTranslations } from '@pepita-react/i18n';

import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

import type { ContentContactCallProps } from '../ContentContactCall';
import { ContentContactCall } from '../ContentContactCall';

import css from './styles.module.scss';

interface ContactCallDialogProps extends ContentContactCallProps {
  onClose: () => void;
}

export function ContactCallDialog({
  supervisor,
  agency,
  onClose,
  onPhoneContactClick,
}: ContactCallDialogProps) {
  const { trans } = useTranslations();

  return (
    <PepitaDialog
      title={trans('act_call_private', {
        capitalize: true,
      })}
      onClose={onClose}
      size={PepitaDialogFrameType.DIALOG}
      content={
        <ContentContactCall
          supervisor={supervisor}
          agency={agency}
          onPhoneContactClick={onPhoneContactClick}
        />
      }
      contentCustomClass={css['in-contactCallDialog__content']}
    />
  );
}
