import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { mapBoundsAtom } from 'src/components/MapComponent/atoms';

import { useQueryParams } from 'src/hooks/useQueryParams';

export const useIsListingSearchQueryEnabled = () => {
  const { lat, lng, zoom } = useQueryParams();
  const mapBounds = useAtomicStateAtomValue(mapBoundsAtom);

  const isSearchBasedOnMap = lat || lng || zoom;
  const hasMapCalculatedBounds = Boolean(mapBounds);

  //if (!isSearchBasedOnMap) we are doing SSR (query doesn't depend on the map)
  //if (isSearchBasedOnMap && hasMapCalculatedBounds) we are doing CSR and need to wait for the map bounds to be calculated (query depends on map)

  return !isSearchBasedOnMap || hasMapCalculatedBounds;
};
