import { capitalize, useTranslations } from '@pepita-react/i18n';

import type { ValidationSchema } from 'src/utils/form-validators';
import { isEmail, isRequired, isTruthy } from 'src/utils/form-validators';

export type SavedFormData = {
  email: string;
  privacy: boolean;
};

export function useSaveRealEstateSchema(): ValidationSchema<SavedFormData> {
  const { trans } = useTranslations();

  return {
    privacy: [isTruthy(trans('act_declare_consent'))],
    email: [
      (data: SavedFormData) => {
        if (!data.email) {
          return isRequired<SavedFormData>(trans('email_field_validation_msg'))(
            data,
            'email'
          );
        }

        return {
          valid: true,
          message: '',
        };
      },
      isEmail(capitalize(trans('insert_valid_email'))),
    ],
  };
}
