import { useEffect, useRef } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useAtomValue, useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { hasMapCustomPositionAtom } from '../atoms/hasMapCustomPosition';
import { searchInfoAtom } from '../atoms/searchInfoAtom';

import { geographySearchAtom } from 'src/components/GeographySearch';

import { useQueryParams } from 'src/hooks/useQueryParams';
import type { RealEstateListSearchWithLocalData as RealEstateLiteListWithLocalData } from 'src/hooks/useRealEstateLiteSearch';

import type { SearchListingData } from 'src/types/real-estate';
import type { SearchParams } from 'src/types/search';

import { deepEqual } from 'src/utils/object';
import { getIsEditing, getSearchName } from '../utils';

export type Result = Nullable<
  SearchListingData | RealEstateLiteListWithLocalData
>;

export const useUpdateSearchInfo = (
  searchParams: SearchParams,
  result: Result | undefined
) => {
  const query = useQueryParams();
  const setSearchInfo = useSetAtom(searchInfoAtom);
  const firstRedirect = useRef<boolean>(true);
  const { user } = useAuthContext();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  const { pag, minLat, minLng, maxLat, maxLng, zoom, ...params } = searchParams;
  const searchToken = result?.suggestedSearchData.token;

  const prevParams = useRef<Nullable<SearchParams>>(null);
  const prevSearchToken = useRef<string>();
  const prevIsAuthenticated = useRef<boolean>(false);
  const prevResult = useRef<Result | null>(null);
  const hasMapCustomPosition = useAtomValue(hasMapCustomPositionAtom);
  const neverSaved = useRef<boolean>(true);

  useEffect(() => {
    const searchParamsAreChanged = !deepEqual(params, prevParams.current);

    prevResult.current = result ?? null;

    setSearchInfo((prevState) => {
      const isEditing = getIsEditing(
        searchParamsAreChanged,
        firstRedirect.current,
        prevState?.isEditing,
        prevState?.searchId,
        Boolean(user),
        geographyData,
        neverSaved.current,
        hasMapCustomPosition
      );

      const searchId = user ? prevState?.searchId : undefined;

      if (searchId) {
        neverSaved.current = false;
      }

      return {
        ...prevState,
        isEditing: Boolean(isEditing),
        searchId,
        resultsCount: result?.count || null,
        searchName: getSearchName(result),
        isFetching: !result,
        showTooltip:
          !isEditing &&
          (prevState?.showTooltip || query['editSearchAction'] === '1'),
        token: result?.suggestedSearchData.token || null,
      };
    });

    if (result) {
      // Stop setting the search info data only when we are sure we have all the data.
      prevParams.current = params;
      prevSearchToken.current = searchToken;
      firstRedirect.current = false;
      prevIsAuthenticated.current = Boolean(user);
    }
  }, [
    result,
    params,
    searchToken,
    user,
    setSearchInfo,
    query,
    geographyData,
    hasMapCustomPosition,
  ]);
};
