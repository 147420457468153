import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { getProductConfig } from 'src/config/product';

import { PepitaBadge, type PepitaBadgeProps } from 'src/libs/ui/pepita-badge';

import type { RealEstate } from 'src/types/real-estate';

import css from './index.module.scss';

export type RealEstateBadgeProps = Omit<PepitaBadgeProps, 'children'> & {
  tier?: RealEstate['visibility'];
};

export function RealEstateBadge({
  tier,
  customClass,
  ...props
}: RealEstateBadgeProps) {
  const { trans } = useTranslations();

  const visibilitiesConfig = getProductConfig('visibilities');

  return tier ? (
    <PepitaBadge
      customClass={clsx(
        css[Styles.badge],
        css[`${Styles.badge}--${visibilitiesConfig[tier].style}`],
        customClass
      )}
      variant="reversed"
      {...props}
    >
      {
        // i18n-extract-disable-next-line
        trans(visibilitiesConfig[tier].label)
      }
    </PepitaBadge>
  ) : null;
}

enum Styles {
  badge = 'in-realEstateBadge',
}
