import { PrivacyCheckbox } from '@indomita-react/privacy-checkbox';
import { useTranslations } from '@pepita-react/i18n';

import type { SimpleForm } from 'src/hooks/useSimpleForm';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import type { SavedFormData } from '../../hooks/useSaveRealEstateSchema';

import css from './styles.module.scss';

interface FormProps {
  form: SimpleForm<SavedFormData>;
}

export function Form({ form }: FormProps) {
  const { trans } = useTranslations();

  return (
    <>
      <div className={css['in-saveRealEstateDialogFormBox']}>
        <PepitaTextField
          type="email"
          name="email"
          floatingLabel
          label={trans('lbl_email')}
          placeholder={trans('act_insert_email')}
          status={form.getErrorMessage('email') ? 'error' : undefined}
          statusMessage={form.getErrorMessage('email')}
          onBlur={form.handleValidateTextInput('email')}
          onChange={form.handleTextInputChange('email')}
          defaultValue={form.data.email ?? ''}
        />
        <PrivacyCheckbox
          error={form.getErrorMessage('privacy')}
          onChange={form.handleCheckboxChange('privacy')}
          value={Boolean(form.data.privacy)}
        />
      </div>
      <PepitaButton
        type="submit"
        variant="accent"
        disabled={form.submitButtonDisabled}
        loading={form.submitButtonLoading}
        onClick={form.handleSubmit}
        block
      >
        {trans('save_ad')}
      </PepitaButton>
    </>
  );
}
