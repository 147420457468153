import { type SyntheticEvent, useState } from 'react';
import spriteListingDetail from '@pepita-fe/sprite-listing-detail/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { RealEstateKey } from 'src/types/real-estate';

import { SavedNoteDialog } from 'src/views/SavedListings/components/SavedNoteDialog';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

interface SavedNoteProps {
  value: string;
  realEstateKey: RealEstateKey | undefined;
  onSubmit: (id: number, value?: string) => void;
}

export function SavedNote({ value, realEstateKey, onSubmit }: SavedNoteProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const {
    realEstate: { disabled, id },
  } = useListingCardContext();

  const { trans } = useTranslations();
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className={clsx(
          css['in-listingCardSavedNote'],
          disabled && css['is-disabled']
        )}
      >
        <div className={css['in-listingCardSavedNote__text']}>
          <PepitaIcon
            name="write"
            spriteUrl={spriteListingDetail}
            customClass={css['in-listingCardSavedNote__icon']}
          />
          <p className={css['in-listingCardSavedNote__paragraph']}>
            <span className={css['in-listingCardSavedNote__preValue']}>
              {trans('lbl_note', { capitalize: true })}
            </span>
            <span className={css['in-listingCardSavedNote__value']}>
              {value}
            </span>
          </p>
        </div>
        <PepitaButton
          iconOnly={maxScreenWidthSm}
          variant="link"
          icon={maxScreenWidthSm ? { name: 'pencil' } : undefined}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            setShowNoteDialog(true);
          }}
        >
          {trans('act_edit')}
        </PepitaButton>
      </div>
      {showNoteDialog && (
        <SavedNoteDialog
          onClose={() => {
            setShowNoteDialog(false);
          }}
          id={id}
          realEstateKey={realEstateKey}
          initialValue={value || ''}
          onSubmit={(value?: string) => {
            onSubmit(id, value);
          }}
        />
      )}
    </>
  );
}
